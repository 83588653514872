import { login, signup } from './data-type';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Rx from "rxjs";
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import Toastr from 'toastr2';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  [x: string]: any;
  toastr = new Toastr();
 
  profileUpdated = new Rx.BehaviorSubject(false);
  cartUpdated = new Rx.BehaviorSubject(false);
  appSettings = new Rx.BehaviorSubject({});
  currentUser = new Rx.BehaviorSubject({});
  
  cartObject: any = {};
  uid:any;
  mobile : any;
  version : any = "";
  userId : any;
  email : any;
  url:" any=''";
  url1: any='https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api'
  push: any;
  next: any;
  name: any;
  constructor(private http:HttpClient,private router:Router) { }


  SignupUser(user: signup){

    this.http.post("http://localhost:3000/signup",user, { observe: 'response' })
    .subscribe((result) => {
      localStorage.setItem('user', JSON.stringify(result.body))
      alert("Succesfully regester")
     

    });
  }

  loginUser(user: login){

    this.http.get(`http://localhost:3000/signup?email=${user.email}&password=${user.password}`, { observe: 'response' })
    .subscribe((result:any) => {
      // if (result && result.body) {
        console.log("**************",result)
      //   localStorage.setItem('user', JSON.stringify(result.body[0]));
      //   this.router.navigateByUrl('/')
      // }

      if(result.body.length > 0){
        if(result.body[0].email === user.email && result.body[0].password === user.password){
          alert("Login Success")
          this.toastr.success('Have fun storming the castle!', 'Miracle Max Says');
          this.router.navigateByUrl('/')
        }else{
          alert("Somthing went wrong")
        }
      }else{
        alert("Please enter valid email & password")
      }
    })
  }

}