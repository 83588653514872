<app-navbar-style-two></app-navbar-style-two>
<div class="wrapper">
<div class="card">
    <li><a routerLink="/profile-authentication" *ngIf="!userEmail" style="font-size:22px;"><i class='bx bx-user' style="margin-left:-2%;font-size:25px;"></i> Sign In / Register</a></li>
    <li><a routerLink="/profile-authentication" *ngIf="userEmail" style="font-size:22px;"><i class='bx bx-user' style="margin-left:-2%;font-size:25px;"></i>{{userEmail}} </a></li>
    <h5 routerLink="/cart" class="bx bx-shopping-bag" style="cursor:pointer;">  Cart</h5>
    <h5 routerLink="/wishlist" class="bx bx-heart" style="cursor:pointer;">  Wishlist</h5>
    <h5 routerLink="/cart" class="bx bx-book" style="cursor:pointer;">  order</h5>
    <h5 routerLink="/cart" class="fa fa-coins" style="cursor:pointer;font-size: 20px;"> Coupon</h5>
    <li><a style="cursor:pointer;" (click)="logout()">logout</a></li>
    <p>A royal parampara started by Dungarshi Maharaj, now savoured by millions of foodies across the globe. Today, Bikajiwala stands tall as the largest producer of Bhujia in the world.</p>
 
	</div>

</div>




