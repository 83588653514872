import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  pageTitle = [
    {
        bgImage: 'assets/img/images/TermsandConditions.gif',
        title: 'Privacy Policy'
    }
]

}
