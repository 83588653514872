import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-deal-in-this-week',
    templateUrl: './deal-in-this-week.component.html',
    styleUrls: ['./deal-in-this-week.component.scss']
})
export class DealInThisWeekComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    dealOfTheWeekContent = [
        {
            title: 'Bhujia',
            paragraph: 'A royal parampara started by Dungarshi Maharaj, now savoured by millions of foodies across the globe. Today, BhujiaVala stands tall as the largest producer of Bhujia in the world.',
            discountPercent: '50% OFF',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP NOW',
            buttonLink: 'shop-full-width-2',
            image:"https://www.bikaji.com/pub/media/wysiwyg/royal-treat.png"
        }
    ]
    dealOfTheWeekImage = [
        {
            img: 'assets/img/Websited.png'
        }
    ]

}