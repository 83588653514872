<app-navbar-style-two></app-navbar-style-two>
<!-- <div class="row mt-5">
    <h1>Forgot Password</h1>
    <h6 class="information-text" style="color: grey; font-size: 15px;">Enter your registered email to reset your password.</h6>
    <div class="form-group">
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
        <p><label for="username" style="color: black; font-size: 20px;">Enter your Email</label></p>
        <button class="form-control">Reset Password</button>
    </div>
    <div class="footer"> -->
        <!-- <h5>New here? <a href="#">Sign Up.</a></h5> -->
        <!-- <h5>Already have an account? <a href="/profile-authentication">Sign In.</a></h5>
    </div>
</div> -->


    <div class="card col-md-6 text-center mt-5" style="margin-left: 23rem;background-color: whitesmoke;">
      <div class="container">
    <main class="form-signin w-100 m-auto">
      <form >
        <img class="mb-4" src="assets/img/logo.png" alt="" width="200px" height="100px">
        <h1 class="h1 mb-3 ">Forgot Password</h1>
        <h6 class="information-text" style="font-size: 15px;color: rgb(110, 108, 108);">Enter your registered email to reset your password.</h6>
    
        <div class="form-floating mb-3 mt-4 " >
          <input type="email" class="form-control text-center"  id="floatingInput" placeholder="name@example.com">
          <label for="floatingInput" style="margin-left: 41%;">Email address</label>
        </div>      
        <button class="w-90 btn btn-lg btn text-white mb-5" type="submit" style="background-color: rgb(168, 46, 46);position: relative;
        top: -10px;">Reset Password</button>
      </form>
    </main>

</div>
</div>
    
    
        
     




