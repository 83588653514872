<div class="container">
  <div class="section-title" *ngFor="let Content of sectionTitle;">
    <h2>{{Content.title}}</h2>
    <p>{{Content.paragraph}}</p>
  </div>
  <!-- <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let Content of productlist;">
          <div class="single-products-item">
              <div class="products-image">
                  <a routerLink="/{{Content.detailsLink}}" class="d-block">
                      <img [src]="Content.Imagepath" alt="image">
                  </a>
                  <ul class="products-button">
                      <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li>
                      <li><a href="{{Content.mainImg}}" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                      <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li>
                  </ul>
                  <a routerLink="/cart" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a>
              </div>
              <div class="products-content">
                  <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                  <div class="price">
                      <span class="new-price">{{Content.newPrice}}</span>
                      <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                  </div>
                  <a routerLink="/wishlist" class="add-to-wishlist-btn"><i class="bx bx-heart"></i></a>
              </div>
              <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span>
              <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
          </div>
      </div>
  </div> -->
</div>
<div class="w3-row w3-container">

  <div class="w3-row" style="position: relative;top: -50px;">
    <!-- <div class="w3-col m2 w3-center" *ngFor="let item of productlist ;let i = index" style="height: 355px;">
      <div *ngIf="item.priceOffPerc>0" class="discount1"> -{{item.priceOffPerc}}%</div>
      <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i>
      <section>
        <img src="{{item.Imagepath}}" class="product-thumb" alt="" style="padding: 30px;
         height: 216px;cursor: pointer;" (click)="productDetail1(item)" id="replies2" />
        <span id="replies" class="price">{{item.purchaseAmount| currency:"&#8377;"}}</span><span id="replies"
          class="actual-price">{{item.price | currency:"&#8377;"}}</span>
        <span id="comment" style="position: relative;top:75px" class="handle" (click)="handleAddToCarta(item)">Add to
          Cart </span>

      </section>
      <div class="product-info">
        <p (click)="productDetail(item)" class="product-brand">{{item.name}}</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>

      </div>
    </div> -->

    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\3.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
        <div class="product-info mt-1">
          <p class="product-brand">Bikaneri Bhujia</p>
          <div class="top"><span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </div>
    
        </div>
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\1.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Namkeen Mixture</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>


    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\2.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Ratlami Sev</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\4.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Lajawab Mix</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>

    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\5.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Mastana Mix</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\6.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Mumbai Mix</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>


    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\7.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Plain Bhujia</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\8.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Plane Bhujia</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>


    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\9.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Plane Bhujia</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\3.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Bikaneri Bhujia </p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>



    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\5.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Masatana Bhujia</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\7.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Plain Bhujia</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>


    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\7.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Plain Namkeen</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\8.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
        <div class="product-info mt-1">
          <p class="product-brand">Plain Namkeen</p>
          <div class="top"><span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </div>
    
        </div>
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      
    </div>


    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\9.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Plain Bhujia</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\8.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Plain Bhujia</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\6.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Mumbai Mix </p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\1.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
        <div class="product-info mt-1">
          <p class="product-brand">Namkeen Mixture</p>
          <div class="top"><span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </div>
    
        </div>
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      
    </div>

    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\5.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
  
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
      <div class="product-info mt-1">
        <p class="product-brand">Mastana Mix</p>
        <div class="top"><span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star checked"></span>
          <span class="fa fa-star"></span>
          <span class="fa fa-star"></span>
        </div>
  
      </div>
    </div>
    <div class="w3-col m2 w3-center" style="height: auto;">
      <div class="discount1"> -8%</div>
      <!-- <i id="comment" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
        (click)="topDealsProductWishList(item._id , i,item)"></i> -->
      <section>
        <img src="assets\img\8.jpg" class="product-thumb" alt="" style="padding: 10px;
      cursor: pointer;"/>
        <!-- <span class="price">₹120</span><span
          class="actual-price">₹130</span>  -->
        <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->
        <div class="product-info mt-1">
          <p class="product-brand">Plain Bhujia</p>
          <div class="top"><span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </div>
    
        </div>
      </section>
      <!-- <button type="button" class="handle">Add to cart</button> -->
    
    </div>
    <!-- <h3>TASTE OF INDIA</h3>
  <div class="row">
    <div class="column">
      <img class="zoom" src="https://cdn.shopify.com/s/files/1/0397/5292/5335/files/Snack_Attack_1_540x.jpg?v=1646641208">
      <h5>Snack Attack Discount Box</h5>
    </div>
    <div class="column">
      <img class="zoom" src="https://cdn.shopify.com/s/files/1/0397/5292/5335/files/Gift_Hampers_540x.jpg?v=1646641208">
      <h5>Gift Hampers!</h5></div>
    <div class="column">
      <img class="zoom" src="https://cdn.shopify.com/s/files/1/0397/5292/5335/files/Chips_1_540x.jpg?v=1646641208">
      <h5>Chips and Dips</h5></div>
    <div class="column">
      <img class="zoom" src="https://cdn.shopify.com/s/files/1/0397/5292/5335/files/Chips_1_540x.jpg?v=1646641208">
      <h5>Snack Attack Discount Box</h5></div>
  </div> -->
