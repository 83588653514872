<app-navbar-style-two></app-navbar-style-two>
<div class="container">
  <div class="row row--top-40">
  </div>
  <div class="row row--top-20">
    <div class="col-md-12">
      <div class="table-container">
        <table class="table">
          <thead class="table__thead">
            <tr>
              <th class="table__th">Product image</th>
              <th class="table__th">Name</th>
              <th class="table__th">Description</th>
              <th class="table__th">Price</th>
              <th class="table__th">Quantity</th>
              <!-- <th class="table__th">Total</th> -->
              <th class="table__th"></th>
            </tr>
          </thead>
          <tbody class="table__tbody">
            <tr class="table-row table-row--chris" *ngFor="let item of cartItems;">
              <td data-column="Product Image" class="table-row__td">
                <img src="{{item.Imagepath}}" alt="" style="width: 120px;">
              </td>
              <td data-column="Name" class="table-row__td">
                <span class="table-row__small">{{item.name}}</span>
              </td>
              <td data-column="Description" class="table-row__td">
                <p class="table-row__p-status">{{item.description}}</p>
              </td>
              <td data-column="Price" class="table-row__td">
                {{item.purchaseAmount}}
              </td>
              <td data-column="Quantity" class="table-row__td">

                <div class="input-counter">
                  <span (click)="deleteFromCart(item)" class="minus-btn"><i class='bx bx-minus'></i></span>
                  <span id="box">{{item.qty}}</span>
                  <span (click)="handleAddToCart(item)" class="plus-btn"><i class='bx bx-plus'></i></span>
                </div>
              </td>
              <td class="table-row__td">
                <svg data-toggle="tooltip" data-placement="bottom" title="Delete" version="1.1" class="table-row__bin"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path
                        d="M436,60h-90V45c0-24.813-20.187-45-45-45h-90c-24.813,0-45,20.187-45,45v15H76c-24.813,0-45,20.187-45,45v30    c0,8.284,6.716,15,15,15h16.183L88.57,470.945c0.003,0.043,0.007,0.086,0.011,0.129C90.703,494.406,109.97,512,133.396,512    h245.207c23.427,0,42.693-17.594,44.815-40.926c0.004-0.043,0.008-0.086,0.011-0.129L449.817,150H466c8.284,0,15-6.716,15-15v-30    C481,80.187,460.813,60,436,60z M196,45c0-8.271,6.729-15,15-15h90c8.271,0,15,6.729,15,15v15H196V45z M393.537,468.408    c-0.729,7.753-7.142,13.592-14.934,13.592H133.396c-7.792,0-14.204-5.839-14.934-13.592L92.284,150h327.432L393.537,468.408z     M451,120h-15H76H61v-15c0-8.271,6.729-15,15-15h105h150h105c8.271,0,15,6.729,15,15V120z"
                        style="fill: rgb(158, 171, 180);"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M256,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C271,186.716,264.284,180,256,180z"
                        style="fill: rgb(158, 171, 180);"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M346,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C361,186.716,354.284,180,346,180z"
                        style="fill: rgb(158, 171, 180);"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M166,180c-8.284,0-15,6.716-15,15v212c0,8.284,6.716,15,15,15s15-6.716,15-15V195C181,186.716,174.284,180,166,180z"
                        style="fill: rgb(158, 171, 180);"></path>
                    </g>
                  </g>
            
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<section class="cart-area">
  <div class="container">
    <form>

      <div class="cart-buttons">
        <div class="row align-items-center">
          <div class="col-lg-7 col-sm-12 col-md-7">
            <div class="shopping-coupon-code">
              <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
              <button type="submit">APPLY COUPON</button>
            </div>
          </div>

          <!-- <div class="col-lg-5 col-sm-12 col-md-5 text-right">
            <a routerLink="/shop-left-sidebar-1" class="default-btn"><i class='bx bx-refresh'></i> UPDATE CART</a>
          </div> -->
        </div>
      </div>
      <div style="font-weight: bold;"></div>
      <div class="cart-totals">
        <ul>
          
          <li>Subtotal <span>{{cartTotal | currency:'INR' }}</span></li>
          <li>Shipping <span>30.00</span></li>
          <li>Total <span>{{30.00 + cartTotal | currency:'INR' }}</span></li>
        </ul>
        <a routerLink="/checkout" class="default-btn"><i class='bx bx-paper-plane'></i> PROCEED TO CHECKOUT</a>
      </div>
    </form>
  </div>
</section>
<div style="visibility: hidden;">Space</div>
<!-- End Cart Area -->