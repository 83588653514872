import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { APIS } from 'src/app/config';
import { PetvaysServiceService } from 'src/app/petvays-service.service';
import { StoreService } from 'src/app/services/store.service';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';
@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss']
})
export class SimpleProductPageComponent implements OnInit {
   productID:any
   product_Id_Details:any
    productId: any;
    recommendedProducts: any[];
    cart: any[];
    show: number;
    qty: any;
    productDetail: any;
    constructor(private router:Router, private userService:UserService, private cartService:CartService, private route:ActivatedRoute, private store:StoreService, private activatedRoute:ActivatedRoute, private http: PetvaysServiceService) { 
         console.log(this.router.getCurrentNavigation().extras.state);
    }

    ngOnInit(): void {
        this.route.params.subscribe((res:any) => {
            this.productID = res.id;
            this.productlistDetails(this.productID)
             
        })
        this.route.params.subscribe((res:any) => {
            this.productID = res.id;
            this.recommandedProductList(this.productID)
             
        }) 
    }

    pageTitle = [
        {
            bgImage: 'https://m.media-amazon.com/images/I/71TZAVWupPL._SX522_.jpg',
            //title: 'Love your Pet More'
        }
    ]

    productlistDetails(productId:any) {
        let formdata = {
            "productId":productId
        }
        console.log("Response from formdata of productDetails", formdata)
        this.store.fetchProductDetail(formdata)
            .subscribe((res: any) => {
                this.productDetail = res;
                console.log("Response from product Detail API", this.product_Id_Details)
            }, (error: any) => {
                console.log(error)
            });
    }
    buy(){
        this.router.navigate(['/cart'])
    }
    recommandedProductList(productId:any) {
        let formdata = {
         "productId":productId,
         "zip":201309
        }
        console.log("recommendedproducts", formdata)
        this.store.recommendedProducts1(formdata).subscribe((result: any) => {
         this.recommendedProducts=result;
          console.log("recommended products", this.recommendedProducts)
        }, (error: any) => {});
      }
      
      handleAddToCarta(productObj) {
        console.log("productObj ", productObj)
        this.cart = []
        let cart = localStorage.getItem("cart");
        if (cart) {
          this.cart = JSON.parse(cart);
          console.log("cart ", this.cart)
          let filtered = this.cart.filter(product => product.id == productObj._id)
          console.log("filtered ", filtered)
          if (filtered.length == 0) {
            var pdata = {
              id: productObj._id,
              qty: this.qty
            }
            console.log(pdata)
            this.cart.push(pdata)
            localStorage.setItem('cart', JSON.stringify(this.cart))
            this.show = 1
          } else {
            console.log(this.show)
            let index = this.cart.indexOf(filtered[0]);
            console.log("index ", index)
            this.cart.splice(index, 1)
            localStorage.setItem('cart', JSON.stringify(this.cart))
            this.show = null
    
          }
        } else {
          console.log("item._id ", productObj._id)
          var pdata = {
            id: productObj._id,
            qty: this.qty
          }
          this.cart.push(pdata)
          localStorage.setItem('cart', JSON.stringify(this.cart))
          this.show = 1
        }
        this.userService.cartUpdated.next(true);
      
       
         
        
      }
}