<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->

<!-- End Page Title Area -->

<!-- Start Products Details Area -->
<section class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <!-- <a href="assets/img/products/products4.jpg" class="popup-btn"> -->
                        <div class="card align-items-center">
                            <img [src]="productDetail.Imagepath" alt="image"> 
                            <!-- <div class="card-block text-center">
                                <h4 class="card-title">Card title</h4>
                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            </div> -->                        
                        </div>
                        <!-- <img [src]="product_Id_Details.multipleImages" alt="image"> -->
                    <!-- </a> -->
                </div>
            </div>
            <div class="col-lg-7 col-md-12" style="position: relative;top: 8px;">
                <div class="products-details-desc">
                    <h3> {{productDetail.name}}</h3>
                    <div class="price">
                        <span class="new-price"> {{productDetail.purchaseAmount}}</span>
                        <span class="old-price">{{productDetail.price}} </span>
                        <span class="discount2"> {{productDetail.priceOffPerc}}% off</span>
                    </div>
                    <p style="font-weight: 700;">{{productDetail.description}}</p>
                    <div class="products-meta">
                        <span>Store Name: <span style="font-weight: 700;">{{productDetail.storeName}}</span></span>
                        <span>Availability: <span class="in-stock">In Stock</span></span>
                        <span>SKU: <a routerLink="/">{{productDetail.units}} </a></span>
                        <span>Tag: <a routerLink="/">{{productDetail.merchandiseCat}}</a></span>
                    </div>
                    <div class="products-add-to-cart">
                        <button (click)="handleAddToCarta(productDetail)" type="submit" class="default-btn"><i class='bx bxs-cart-add'></i>Add to Cart</button>
                        <button style="position: relative;left: 11px;" (click)="buy()" type="submit" class="default-btn"><i class='bx bxs-cart-add'></i>Go to Cart</button>
                    </div>
                    <!-- <a routerLink="/wishlist" class="add-to-wishlist-btn"><i class='bx bx-plus'></i> Add to Wishlist</a> -->
                    <!-- <div class="products-share">
                        <ul class="social">
                            <li><span>Share:</span></li>
                            <li><a href="https://instagram.com/petvays?igshid=YmMyMTA2M2Y=" style="color: #000;background-color: #FBDA6F;border: none;" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
            
            <!-- <div class="col-lg-12 col-md-12">
                <div class="products-details-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Description</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="additional-information-tab" data-bs-toggle="tab" data-bs-target="#additional-information" type="button" role="tab" aria-controls="additional-information" aria-selected="false">Additional Information</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews (2)</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel">
                            <p>This story, dazzling in its powerful simplicity and soul-stirring wisdom, is about an Andalusian shepherd boy named Santiago who travels from his homeland in Spain to the Egyptian desert in search of a treasure buried near the Pyramids. Lorem ipsum dolor sit.</p>
                            <ul>
                                <li>Instant <strong>Fiwan</strong> bestseller</li>
                                <li>Translated into 18 languages</li>
                                <li>#1 Most Recommended Book of the year.</li>
                                <li>A neglected project, widely dismissed, its champion written off as unhinged.</li>
                                <li>Yields a negative result in an experiment because of a flaw in the design of the experiment.</li>
                                <li>An Amazon, Bloomberg, Financial Times, Forbes, Inc., Newsweek, Strategy + Business, Tech Crunch, Washington Post Best Business Book of the year</li>
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="additional-information" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Handle Height</td>
                                            <td>40-45″</td>
                                        </tr>
                                        <tr>
                                            <td>Width</td>
                                            <td>24″</td>
                                        </tr>
                                        <tr>
                                            <td>Wheels</td>
                                            <td>12″</td>
                                        </tr>
                                        <tr>
                                            <td>Dimensions</td>
                                            <td>10 × 10 × 10 cm</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Iron</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reviews" role="tabpanel">
                            <div class="products-review-comments">
                                <div class="user-review">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">James Anderson</span>
                                    </div>
                                    <span class="d-block sub-comment">Excellent</span>
                                    <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">Sarah Taylor</span>
                                    </div>
                                    <span class="d-block sub-comment">Video Quality!</span>
                                    <p>Was really easy to implement and they quickly answer my additional questions!</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">David Warner</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Coding!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user4.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">King Kong</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Video!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                            </div>
                            <div class="review-form-wrapper">
                                <h3>Add a review</h3>
                                <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="rating">
                                                <input type="radio" id="star5" name="rating" value="5" /><label for="star5"></label>
                                                <input type="radio" id="star4" name="rating" value="4" /><label for="star4"></label>
                                                <input type="radio" id="star3" name="rating" value="3" /><label for="star3"></label>
                                                <input type="radio" id="star2" name="rating" value="2" /><label for="star2"></label>
                                                <input type="radio" id="star1" name="rating" value="1" /><label for="star1"></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name *">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email *">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <p class="comment-form-cookies-consent">
                                                <input type="checkbox" id="test1">
                                                <label for="test1">Save my name, email, and website in this browser for the next time I comment.</label>
                                            </p>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit">SUBMIT</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Products Details Area -->
<h4 style="position: relative;top: 33px;left: 18px;">Similar Product</h4>
<div class="w3-row w3-container" style="position: relative;top: 33px;">
      
    <div class="w3-row">
      <div class="w3-col m2 w3-center" *ngFor="let item of recommendedProducts ;let i = index" style="height: 390px;">
        <div *ngIf="item.priceOffPerc>0" class="discount1"> -{{item.priceOffPerc}}%</div>
        <i id="comment" style="position: relative;top: 9px;left: -80px;font-size: 20px;
         cursor: pointer;" class="fas fa-heart float-right" [style.color]="item.isSelect ? 'red' : ''"
          (click)="topDealsProductWishList(item._id , i,item)"></i>
        <section>
          <img src="{{item.Imagepath}}" class="product-thumb" alt="" style="padding: 30px;
         height: 216px;cursor: pointer;" (click)="productDetail1(item)"/>
            <p (click)="productDetail(item)" class="product-brand">{{item.name}}</p>
          <span class="price">{{item.purchaseAmount| currency:"&#8377;"}}</span><span
            class="actual-price" style="position: relative;
            left: 7px;"> <del>{{item.price | currency:"&#8377;"}}</del></span> 
          <!-- <span class="handle" (click)="handleAddToCarta(item)">Add to Cart </span> -->

        </section>
        <button type="button" style="position: relative;
        top: 10px;background-color: #8C0029;color: white; width:146px; padding: 5px;" (click)="handleAddToCarta(item)" class="handle">Add to cart</button>
       
      </div>

    </div> 
    </div>
  