import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userEmail:string;

  constructor() { }

  ngOnInit(): void {

 
    this.userEmail= localStorage.getItem("userEmail")
    console.log("HI",this.userEmail)
    
  
  }

  logout(){
    
  }
}
