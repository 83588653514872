import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class StoreService {
  url: any='https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api'
    pDetail: any;
  constructor(private http:HttpClient) { }
  namkeen(data:any): Observable<any> {
    return this.http.post(this.url+'/getProducts',data)
  }
  fetchProductDetail(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchProductDetail', postData)
  }
  recommendedProducts1(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchProductrecommendations', postData)
  }
  getcart(postData: any): Observable<any>{
    return this.http.post(this.url+'/getCartProducts',postData)
  }
  getProduct(postData : any): Observable<any>{
    return this.http.post(this.url+'/getProducts',postData)
  }
}

