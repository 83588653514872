<!-- Start Main Banner Area -->
<base href="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/">
<div id="slider">
    <figure>
        <img src="assets\img\Website Banner1.jpg" alt style="height: auto;">
        <img src="assets\img\Website Banner.jpg" alt style="height: auto;">

        <img src="assets\img\Website Banner1.jpg" alt style="height: auto;">
        <img src="assets\img\Website Banner.jpg" alt style="height: auto;">
        <img src="assets\img\Website Banner1.jpg" alt style="height: auto;">

        <!-- <img src="https://share.snacks.com/images/banner/Snacks.com-Desktop-Banner-Cheetos.jpg" alt>
<img src="https://share.snacks.com/images/banner/Snacks.com-Desktop-Banner-Superbowl2.jpg" alt>
<img src="https://share.snacks.com/images/banner/Snacks.com-Desktop-Banner-DoritosBBQ.jpg" alt> -->
    </figure>
</div>

<!-- <div class="home-slides-two">
    <owl-carousel-o [options]="homeSlidesOptions">
        <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
            <div class="banner-area">
                <div class="container-fluid">
                    <div class="row align-items-center"> -->
<!-- <div class="col-lg-4 col-md-12">
                            <div class="banner-content">
                                <h1>{{Content.title}}</h1>
                                <p>{{Content.paragraph}}</p>
                                <a routerLink="/{{Content.buttonLink}}" class="default-btn"><i class="{{Content.buttonIcon}}"></i> {{Content.buttonText}}</a>
                            </div>
                        </div> -->
<!--         
                        <div> -->
<!-- <div class="banner-image bg-color1">
                                <img [src]="Content.image" style="height: 400px;" alt="image">
                            </div> -->
<!-- </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div> -->
<!-- End Main Banner Area -->