<app-navbar-style-two></app-navbar-style-two>
<!-- Start Page Title Area -->
<!-- <div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div> -->
<!-- End Page Title Area -->
<!-- Start Profile Authentication Area -->
<section class="profile-authentication-area ptb-100" *ngIf="!showLogin">
    <div class="container">
        <div class="row" style="margin-top: 59px;">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>Login</h2><hr>
                    <form #LoginForm="ngForm" (ngSubmit)="Login(LoginForm.value)" (ngsubmit)="openLogin()">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input autocomplete="off" type="email" class="form-control" placeholder="Mobile" required
                                #email="ngModel" name="email" ngModel />
                            <div *ngIf="email.invalid && email.touched" class="text-danger">
                                *Email is required!!
                            </div>

                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input autocomplete="off" type="password" class="form-control" placeholder="Password"
                                required #password="ngModel" name="password" ngModel />
                            <div *ngIf="password.invalid && password.touched" class="text-danger">
                                *Passowrd is Required!!
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/forgot" class="lost-your-password">Forgot Password?</a>
                            </div>
                        </div>
                        <button type="submit" [disabled]="LoginForm.invalid">LOG IN</button>
                        <a (click)="openSignUp()" style="color: blue;cursor:pointer;">Don't Have Account? Click to SignUp</a>
                    </form>
                </div>

            </div>

            <img src="assets/img/logo.png" alt="" style="width:600px;margin-top: -5%;">
        </div>
    </div>
</section>

<!-- Start Profile Authentication Area -->


<!---------------------- SignUp Page ------------------------->

<section class="profile-authentication-area ptb-100" *ngIf="showLogin">
    <div class="container">
        <div class="row" style="margin-top: 59px;">
<div class="col-lg-6 col-md-12">
    <div class="login-form">
        <h2>Register</h2>
        <hr>
        <form #registerForm="ngForm" (ngSubmit)="signupform(registerForm.value)"  (ngsubmit)="openSignUp()">
            <div class="form-group">
                <label for="name">UserName<span class="text-danger">*</span></label>
                <input autocomplete="off" type="text" id="name" required #name="ngModel" name="name"
                    class="form-control" placeholder="username" ngModel minlength="5" />
                <div *ngIf="name.invalid && name.touched" class="text-danger">
                    *User Name is Required.
                </div>
            </div>

            <div class="form-group">
                <label for="email">Email<span class="text-danger">*</span></label>
                <input autocomplete="off" type="email" minlength="3" class="form-control"
                    placeholder="Email" name="email" required #email="ngModel" ngModel />
                <div *ngIf="email.invalid && email.touched" class="text-danger">
                    *Please Enter Valid Email
                </div>
            </div>
            <div class="form-group">
                <label for="address">Address</label>
                <input autocomplete="off" type="email" class="form-control" placeholder="Address"
                    #address="ngModel"  required name="address" ngModel />
                <div *ngIf="address.invalid && address.touched" class="text-danger">
                    *Address is Required!!
                </div>

            </div>
            <div class="form-group ">
                <label for="mobile">Mobile Number</label>
                <input autocomplete="off" type="text" class="form-control" placeholder="Mobile Number"
                    required #mobile="ngModel" required name="mobile" ngModel />
                <div *ngIf="mobile.touched && mobile.invalid" class="text-danger">
                    *Mobile Number is Mandotary!!

                </div>
            </div>
            <div class="form-group">
                <label for="psw">Password<span class="text-danger">*</span></label>
                <input autocomplete="off" type="password" class="form-control" placeholder="Password"
                    required="" #password="ngModel" minlength="3" name="password" ngModel />
                <div *ngIf="password.touched && password.invalid" class="text-danger">
                    *Password is Mandotary!!
                </div>

            </div>

            <p class="description">The password should be at least eight characters long. To make it
                stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p>
            <button type="submit" [disabled]="registerForm.invalid">REGISTER</button>
            <a (click)="openLogin()" style="color: blue;cursor:pointer;">Already Have Account? Click to Login</a>
        </form>
    </div>
</div>
<img src="assets/img/logo.png" alt="" style="width:600px;margin-top: -8%;">
</div>
</div>
</section>