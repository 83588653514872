import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { CartService } from 'src/app/services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  productlist: any;
  cart: any[];
  show: number;
  qty: any;
  searchResultData: any[];
  searchValue: any;
  searchParams: any;
  offset: any;
  resultsAvailable: boolean;
  resultsNotAvailable: number;
  city: any;

  constructor(private routerActivate: ActivatedRoute, private store:StoreService, private userService: UserService, private cartService:CartService, private router:Router) { }

  ngOnInit(): void {
      this.resetOption = [this.options[0]];
      this.getProduct()
      this.searchResultData=[];
    
      // console.log("enter in ngOnInit function")
      this.routerActivate.queryParams.subscribe(params => {
        this.searchValue = params.search;
        console.log(this.searchValue,'search..');
        if (this.router.getCurrentNavigation().extras.state) {
          this.searchParams = this.router.getCurrentNavigation().extras.state.search;
        }
      });
      this.getProducts(this.searchValue,"");
      // console.log("@@@",this.searchValue,this.city)
      this.searchParams=this.searchValue
  }
  getProducts(search:any,city){
    this.offset+=0;
    const formData = {
      "search": search,
      limit : 50,
      offset : this.offset
    }
    this.store.getProduct(formData).subscribe((result: any) => {
      console.log(result.length);
      this.searchResultData=[...this.searchResultData,...result];
      if(this.searchResultData.length>0){
          this.resultsAvailable=true;
          this.resultsNotAvailable=0;
          console.log("hello")
      
      }
      else{
        this.resultsNotAvailable=1;
        console.log("hiiii")
        
      }
  })
  }
  pageTitle = [
      {
          bgImage: 'https://www.sonnamera.com.sg/var/ezdemo_site/storage/images/media/images/site-image/brands/haldirams-banner/7948-1-eng-US/haldirams-banner.jpg',
          // title: 'Shop Full Width'
      }
  ]
  singleProductsItem = [
      {
          mainImg: 'https://badshahnamkeen.com/image/catalog/namkeen/aalo_bhujiya.jpg',
          title: 'Ergonomic Desk Sofa',
          newPrice: '$150.00',
          oldPrice: '',
          sale: '',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'https://m.media-amazon.com/images/I/81W78eIVS0L._SX522_.jpg',
          title: 'Office Desk Sofa',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'https://kreate.sgp1.cdn.digitaloceanspaces.com/images/original/65103020-7e21-4fbe-9f09-b69ed25d270a.webp',
          title: 'Swivel Sofa',
          newPrice: '$223.99',
          oldPrice: '',
          sale: '',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'https://www.bikanerexpress.com/wp-content/uploads/2021/09/Bikaner-Namkeen2.jpg',
          title: 'Home Alisa Sofa',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: '',
          outOfStock: 'Out of Stock',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products8.jpg',
          title: 'Grey Harrington Sofa',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products9.jpg',
          title: 'Home Alisa Sofa',
          newPrice: '$223.99',
          oldPrice: '',
          sale: '',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products10.jpg',
          title: 'Wood Patio Chair',
          newPrice: '$226.00',
          oldPrice: '',
          sale: '',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products11.jpg',
          title: 'Brown Vinyl Padded',
          newPrice: '$107.99',
          oldPrice: '$140.99',
          sale: '',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products12.jpg',
          title: 'Antique Walnut',
          newPrice: '$98.99',
          oldPrice: '',
          sale: '',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products6.jpg',
          title: 'Swivel Sofa',
          newPrice: '$223.99',
          oldPrice: '',
          sale: '',
          outOfStock: '',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products7.jpg',
          title: 'Home Alisa Sofa',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: '',
          outOfStock: 'Out of Stock',
          detailsLink: 'simple-product'
      },
      {
          mainImg: 'assets/img/products/products8.jpg',
          title: 'Grey Harrington Sofa',
          newPrice: '$199.00',
          oldPrice: '$210.00',
          sale: 'Sale!',
          outOfStock: '',
          detailsLink: 'simple-product'
      }
  ]

  // For Pagination
  shopFullWidth: number = 1;

  // Category Select
  singleSelect: any = [];
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  resetOption: any;
  config = {
      displayKey: "name",
      search: true
  };
  options = [
      {
          name: "Default",
      },
      {
          name: "Popularity",
      },
      {
          name: "Latest",
      },
      {
          name: "Price: low to high",
      },
      {
          name: "Price: high to low",
      }
  ];
  searchChange($event) {
      console.log($event);
  }
  reset() {
      this.resetOption = [];
  }
  getProduct(){
      let data={"search": "bhujia", "limit": 50, "offset": 0}
      this.store.namkeen(data).subscribe((res)=>{
          this.productlist=res
          console.log("product",this.productlist)
      })
  }

  productDetail1(productItem: any) {
  console.log("hello hi ", productItem)
  this.router.navigate(['./simple-product/' + productItem._id])
}
handleAddToCarta(productObj,productItem) {
  console.log("productObj ", productObj)
  this.cart = []
  let cart = localStorage.getItem("cart");
  if (cart) {
    this.cart = JSON.parse(cart);
    console.log("cart ", this.cart)
    let filtered = this.cart.filter(product => product.id == productObj._id)
    console.log("filtered ", filtered)
    if (filtered.length == 0) {
      var pdata = {
        id: productObj._id,
        qty: this.qty
      }
      console.log(pdata)
      this.cart.push(pdata)
      localStorage.setItem('cart', JSON.stringify(this.cart))
      this.show = 1
    } else {
      console.log(this.show)
      let index = this.cart.indexOf(filtered[0]);
      console.log("index ", index)
      this.cart.splice(index, 1)
      localStorage.setItem('cart', JSON.stringify(this.cart))
      this.show = null

    }
  } else {
    console.log("item._id ", productObj._id)
    var pdata = {
      id: productObj._id,
      qty: this.qty
    }
    this.cart.push(pdata)
    localStorage.setItem('cart', JSON.stringify(this.cart))
    this.show = 1
  }
  this.userService.cartUpdated.next(true);

 
   
  
}
}