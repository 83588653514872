<!-- Start Footer Area -->
<footer class="footer-area pt-100 {{bgClass}} mt-5" style="background-color: #f5f5f5;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6" style="margin-top: 20px;">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo d-inline-block mx-3">
                        <img src="assets/img/logo.png" alt="logo" height="120px" width="120px">
                    </a>
                    <p class="text" style="text-align: justify;margin-left: -40px;width: 250px;">A royal parampara started by Dungarshi Maharaj, now savoured by millions of foodies across the globe. Today, Bhujiavala stands tall as the largest producer of Bhujia in the world.</p>
                    <ul class="social-links " style="margin-left: -40px;">
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li> -->
                        <li><a href="https://www.instagram.com/Bikajivala/" target="_blank"><i class='bx bxl-instagram text-danger' style="font-size: 30px;margin-top: 1px;"></i></a></li>
                        <li><a href="/" target="_blank"><i class='bx bxl-facebook text-primary' style="font-size: 30px;margin-top: 1px;"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6" style="margin-top: 20px;">
                <div class="single-footer-widget">
                    <h3>About Us</h3>
                    <ul class="links-list">
                        <li><a routerLink="/">Gallery</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/">Bhujiavala.com Offers</a></li>                       
                        <!-- <li><a routerLink="/">Rabbits (Coming Soon)</a></li>
                        <li><a routerLink="/">Fish (Coming Soon)</a></li> -->
                        <!-- <li><a routerLink="/shop-full-width-1">Shoes</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6" style="margin-top: 20px;">
                <div class="single-footer-widget">
                    <h3>Get To Know Us</h3>
                    <ul class="links-list">
                        <li><a routerLink="">Cancellation-and-Refund</a></li>
                         <li><a routerLink="">Terms-and-Conditions</a></li>
                         <li><a routerLink="/privacy">Privacy</a></li>
                        <li><a routerLink="">Shipping-policy</a></li>
                        <!-- <li><a routerLink="/customer-service">Return Policy</a></li> -->
                        <!-- <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li> -->
                        <!-- <li><a routerLink="/about">About Us</a></li> -->
                        <!-- <li><a routerLink="/about">Careers</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6" style="margin-top: 20px;">
                <div class="single-footer-widget">
                    <h3>For Online Order Enquiry</h3>
                    <ul class="footer-contact-info">
                        <li>Call 1800 102 9046 (Mon-Sat 11 AM to 5 PM) or email on online@Bhujivala.com</li>
                        <li>Email: <a href="mailto:info@bikajivala.com">info@bhujiavala.com</a></li>
                        <li>Phone: <a href="tel:(+91)9711416606">(+91)9711416606</a></li>
                        <!-- <li>Fax: <a href="tel:+55587154756">+55 587 154756</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <section class="subscribe-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let SubscribeContent of subscribeArea;" style="background-image: url({{SubscribeContent.bgImage}});">
        <div class="container" style="position: relative;top: -70px;">
            <div class="section-title">
                <!-- <span class="sub-title">{{SubscribeContent.subTitle}}</span> -->
                <h5>{{SubscribeContent.title}}</h5>
            </div>
    
            <form class="newsletter-form">
                <label><i class='bx bx-envelope-open'></i></label>
                <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                <button type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> SUBSCRIBE NOW</button>
            </form>
        </div>
    </section>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;">
                    <p> Copyright © 2023  <strong style="color: #000;font-weight:900;">Bhujiavala</strong>. All Rights Reserved.<a href="https://petvays.com.com/" target="_blank"></a></p>
                </div>

                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/customer-service">Privacy Policy</a></li>
                        <li><a routerLink="/customer-service">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Sidebar Modal Area -->
<div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal"><i class='bx bx-x'></i></button>
            <div class="modal-body">
                <div class="logo">
                    <a href="index.html" class="d-inline-block"><img src="assets/img/images/logo.png" alt="image" height="150px" width="150px"></a>
                </div>
                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products5.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products6.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products7.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products8.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products9.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products10.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-contact-info">
                    <h4>
                        <a href="tel:(+91)9711416606">(+91)9711416606</a><br><br>
                        <span>OR</span><br><br>
                        <a href="mailto:info@bikajivala.com">info@bhujiavala.com</a>
                    </h4>
                </div>
                <br>
                <ul class="social-list">
                    <li><span style="color: #666666;">Follow Us On:</span></li>
                    <!-- <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> -->
                    <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                    
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal Area -->

<!-- Start QuickView Modal Area -->
<div class="modal fade productsQuickView" id="productsQuickView" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="products-image">
                        <img src="assets/img/products/products1.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="products-content">
                        <h3>Rivet Farr Lotus</h3>
                        <div class="price">
                            <span class="new-price">$150.00</span>
                        </div>
                        <div class="products-review">
                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <a href="#" class="rating-count">3 reviews</a>
                        </div>
                        <p>Nemo malesuada animi consectetur, cras consectetuer laborum tenetur, cum, lacus nemo imperdiet facilisis! Aute metus, lorem primis anim. Eros dolorem.</p>
                        <ul class="products-info">
                            <li><span>Vendor:</span> <a href="#">Lereve</a></li>
                            <li><span>Availability:</span> <a href="#">In stock (7 items)</a></li>
                            <li><span>Products Type:</span> <a href="#">Chair</a></li>
                            <li><span>Categories:</span> <a href="#">Chair</a></li>
                        </ul>
                        <div class="products-add-to-cart">
                            <div class="input-counter">
                                <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                <input type="text" value="1" min="1">
                                <span class="plus-btn"><i class='bx bx-plus'></i></span>
                            </div>
                            <button type="submit" class="default-btn"><i class='bx bx-cart'></i> ADD TO CART</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End QuickView Modal Area -->

<!-- Start Sidebar Modal Area -->
<div class="productsFilterModal modal right fade" id="productsFilterModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="bx bx-x"></i> Close</span>
            </button>
            <div class="modal-body">
                <aside class="widget-area p-0">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/shop-left-sidebar-1">Accessories</a><span class="post_count">(5)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Men</a><span class="post_count">(7)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Women</a><span class="post_count">(3)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Sofa</a><span class="post_count">(2)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Uncategorized</a><span class="post_count">(8)</span></li>
                        </ul>
                    </div>
                    <div class="widget widget_colors">
                        <h3 class="widget-title">Filter By Color</h3>
                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="gray">Gray</span>
                                    <span>Gray</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="green">Green</span>
                                    <span>Green</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="red">Red</span>
                                    <span>Red</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="black">Black</span>
                                    <span>Black</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="brown">Brown</span>
                                    <span>Brown</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_price_filter">
                        <h3 class="widget-title">Filter by Price</h3>
                        <div class="collection_filter_by_price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055" name="filter_by_price" data-step="10">
                        </div>
                    </div>
                    <div class="widget widget_top_rated_products">
                        <h3 class="widget-title">Our Best Sellers</h3>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Long Sleeve Leopard</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Causal V-Neck Soft</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star-half'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Yidarton Women's Comfy</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Womens Tops Color</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal Area -->