import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from 'src/app/services/store.service';
@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {

    public products : any = [];
    public grandTotal !: number;
  cartItems: any;
  check: boolean;
  n: any;
  cartTotal: number;
  cart: any[];
    constructor(private cartService : CartService, private userService:UserService, private store:StoreService) { }
  
    ngOnInit(): void {
      this.checkk()
    this.loadCartItems()
    this.userService.cartUpdated.subscribe(res => {
      let cart = localStorage.getItem('cart');
      if(cart){
        this.n = JSON.parse(cart).length;

    console.log("userService cartUpdated n", this.n)
  
      }
    })
    }
   
  
    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Cart'
        }
    ]
    loadCartItems() {
      this.checkk();
      // console.log(this.check)
      let data = {
        cart: JSON.parse(localStorage.getItem('cart'))
      }
      this.store.getcart(data).subscribe((res) => {
        // console.log(res)
        this.cartItems = res;
        this.calcCartTotal();
      })
  
    }
    async calcCartTotal() {
          this.cartTotal = 0
          // console.log("gcgvchvchvbchvbjvbjvbjnvbnjvb")
          for (var x in this.cartItems) 
           {
            this.cartTotal = this.cartTotal + (this.cartItems[x].purchaseAmount)
            }
      //       this.deliveryFess();
            //console.log("hi")
          }
    
        
      handleAddToCart(item) {
        ++item.qty;
        this.calcCartTotal();
        this.loadCartItems();
    
    
      }
      deleteFromCart(item) {
        if (item.qty > 1) {
    
          --item.qty;
          
          this.calcCartTotal();
          this.loadCartItems();
    
        }
    
        this.userService.cartUpdated.next(true);
      }
    checkk() {
      var check = JSON.parse(localStorage.getItem('cart'))
      if (check) {
        if (check.length > 0) {
          this.check = true;
        } else {
          this.check = false;
        }
      } else {
        this.check = false;
      }
    }
}