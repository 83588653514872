import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
    n: any;
    totalItem: any;
    length:any
    userPassword: string;
    userEmail: string;
    user: any;
   a: any;
  check:boolean= false;
  showLogin= false;

    constructor(private loginservice:UserService, private router:Router) {
        this.loginservice.cartUpdated.subscribe(res => {
            let cart = localStorage.getItem('cart');
            if(cart){
              this.n = JSON.parse(cart).length;
            }
          })

         
     }

    ngOnInit(): void {
        
     
     
      if(localStorage){

        this.userPassword = localStorage.getItem("userPassword")
        this.userEmail = localStorage.getItem("userEmail")
        console.log("HI",this.userEmail)
        
        
      }
      
    
  
    }

    
 
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    fetchCatStoreTerm() {
        this.router.navigate(['/search'])
      }

      logout(){
        localStorage.setItem('userEmail',"")
        localStorage.setItem('userPassword',"")
        this.userPassword="";
        this.userEmail="";
     
        this.router.navigate(['/']);
      }

    
  

}