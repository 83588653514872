<app-navbar-style-two></app-navbar-style-two>
<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});height: 400px;" >
    <!-- <h1>{{Content.title}}</h1> -->
    <ul>
        <!-- <li><a routerLink="/" style="color:rgb(107, 0, 128);font-weight: bold;">Home</a></li> -->
        <!-- <li style="color:rgb(11, 127, 143);font-weight: bold;">{{Content.title}}</li> -->
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="about-content" *ngFor="let Content of aboutContent;">
           
            <span class="sub-title text-dark mt-3" style="font-size: 25px;font-weight: bold;">{{Content.subTitle}}</span>
            <p>{{Content.title}}</p>
            <p style="text-align: justify;">{{Content.paragraph}}</p>

            <div class="content" *ngFor="let Text of Content.content">
                <h3>{{Text.title}}</h3>
                <p style="text-align: justify;">{{Text.paragraph}}</p>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let Image of aboutImage;">
                <div class="about-image text-center">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>
        </div> -->
    </div>
</section>
<!-- End About Area -->

<app-why-choose-us></app-why-choose-us>

<app-video></app-video>

<app-testimonials></app-testimonials>

<app-facility-style-one></app-facility-style-one>