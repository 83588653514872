<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <!-- <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> -->
</div>
<!-- End Page Title Area -->

<!-- Start Products Details Area -->
<section class="products-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <a href="https://bhikharamchandmal.in/pub/media/catalog/tmp/category/Namkeens.png" class="popup-btn">
                        <img src="https://bhikharamchandmal.in/pub/media/catalog/tmp/category/Namkeens.png" alt="image">
                    </a>
                    <a href="https://bhikharamchandmal.in/pub/media/catalog/tmp/category/iStock-1299040567.jpg" class="popup-btn">
                        <img src="https://bhikharamchandmal.in/pub/media/catalog/tmp/category/iStock-1299040567.jpg" alt="image">
                    </a>
                   
                </div>
            </div>
            <div class="col-lg-7 col-md-12" style="position: relative; top: 29px;">
                <div class="products-details-desc products-details-desc-sticky">
                    <h3>About Us</h3>
                    <p>Bikajivala Namkeen endeavours to be at the helm of the Food Revolution the nation with a vision to cement India’s place on the World Food Map. Focusing on promoting Indian heritage, culture and cuisine worldwide, the company has now customised Indian food for the overseas market as well. Introducing the world to our famed traditional hospitality, Pooja Namkeen is a name that has its roots in the centuries’ old native cuisine.</p>
                    <p>Along with namkeen, the brand also has diversified into bakery products such as suji toast and different types of cookies which are wildly popular with the masses. The products on offer personify the culture of the city of Delhi with its rich heritage and vibrancy. 
                    </p>
                    <p>The taste of New Delhi is what you get when you try some of the delicious namkeens and sweets from the house of Bikajivala Namkeen.From an assortment laid out during festivals, to munching with friends or a midnight snack craving, Pooja Namkeen will always be a part of your household and its everlasting happiness. The company takes great pride in filling the shelves of its millions of customers with its products and enthralling the taste buds of children, adults and the elderly alike</p>
                    <p>Backed by the experiences and expertise of over three decades, Bikajivala Namkeen stands today as one of the brand leaders in this highly sensitive market of ready to eat snacks Relying solely upon uncompromising quality, Bikajivala Namkeen has come a long way as an technologically advanced organization which began its journey as a cottage industry.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Details Area -->