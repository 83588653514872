<!-- Start Facility Area -->
<section class="facility-area ptb-100 bg-f5f5f5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="facility-content">
                    <div class="item" *ngFor="let Content of facilityContent;">
                        <i class='{{Content.icon}}'></i>
                        <h3>{{Content.title}}</h3>
                        <p>{{Content.paragraph}}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="facilityImg text-center" *ngFor="let Img of facilityImg;">
                    <!-- <video style="width: 100%; height: 400px;" autoplay loop class="library" preload="auto" data-config="some-js-object-here">
                        <source src="assets/img/images/PetVays-video.mp4" type='video/mp4'>
                    </video> -->
                    <img src="assets/img/Websited.png" style="height:auto;">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Facility Area -->