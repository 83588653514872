<app-navbar-style-two ></app-navbar-style-two>

<app-hometwo-banner></app-hometwo-banner>

<app-partner></app-partner>
<app-deal-in-this-week></app-deal-in-this-week>

<section class="products-area pt-100 pb-70">
    <app-new-products></app-new-products>
</section>
<section class="products-area ptb-100 bg-f5f5f5">
    <app-upcoming-products></app-upcoming-products>
</section>
<!-- <app-homeone-categories></app-homeone-categories> -->
<div class="products-area ptb-100">
    <app-trending-products></app-trending-products>
</div>

<app-facility-style-two></app-facility-style-two>

<div class="products-area ptb-100" >
    <app-popular-products></app-popular-products>
</div>

<app-testimonials></app-testimonials>

<!-- <app-blog></app-blog> -->

<app-subscribe></app-subscribe>