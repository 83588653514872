import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
@Component({
    selector: 'app-new-products',
    templateUrl: './new-products.component.html',
    styleUrls: ['./new-products.component.scss']
})
export class NewProductsComponent implements OnInit {
    productlist: any;
    cart: any[];
    qty: any;
    show: number;

    constructor(private store:StoreService, private userService: UserService, private cartService:CartService, private router:Router) { }

    ngOnInit(): void {
        this.getProduct()
    }

    sectionTitle = [
        {
            title: "Top Products",
            paragraph: "A royal parampara started by Dungarshi Maharaj, now savoured by millions of foodies across the globe. Today, BhujiaVala stands tall as the largest producer of Bhujia in the world."
        }
    ]
    singleProductsItem = [
        {
            mainImg: 'https://www.bikaji.com/pub/media/catalog/product/cache/2765542505660baab28ecd555e27366e/c/r/crusty-nuts-front_2_.png',
            title: 'Crusty Nuts',
            newPrice: '226.00',
            oldPrice: '',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'slider-product'
        },
        {
            mainImg: 'https://www.bikaji.com/pub/media/catalog/product/cache/2765542505660baab28ecd555e27366e/b/h/bhujia_400gm_ab-front.png',
            title: 'Bikaneri Bhujia',
            newPrice: '98.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'slider-product'
        },
        {
            mainImg: 'https://m.media-amazon.com/images/I/71TZAVWupPL._SX522_.jpg',
            title: 'Shahi Mixture',
            newPrice: '107.99',
            oldPrice: '140.99',
            sale: '',
            outOfStock: '',
            detailsLink: 'slider-product'
        },
        {
            mainImg: 'https://cdn.shopify.com/s/files/1/0655/7735/3467/products/Capture_26dfbaa7-d43a-447f-bdd5-697be6127d73.png?v=1669622978',
            title: 'Masala Boondi',
            newPrice: '98.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'slider-product'
        },
        {
            mainImg: 'https://www.bigbasket.com/media/uploads/p/l/40185665_3-sharvas-snacks-till-murukku.jpg',
            title: 'Snacks',
            newPrice: '150.00',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'slider-product'
        },
        
        {
            mainImg: 'https://5.imimg.com/data5/VO/GI/MY-1902141/classic-salted-500x500.png',
            title: 'Roasted Crushed Peanuts',
            newPrice: '199.00',
            oldPrice: '210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'slider-product'
        },
        {
            mainImg: 'https://www.jiomart.com/images/product/original/491598270/bikaji-masala-munch-soya-sticks-35-g-product-images-o491598270-p590152647-0-202207251745.jpg',
            title: 'Soya Sticks',
            newPrice: '199.00',
            oldPrice: '210.00',
            sale: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'slider-product'
        },
        {
            mainImg: 'https://afro-indian24.de/1619-large_default/haldirams-falahari-mix-200g.jpg://m.media-amazon.com/images/I/7TopRight,0,0_SX423SY550SH20_.jpg',
            title: 'Falahari Mixture',
            newPrice: '212.99',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'slider-product'
        }
     
       
    ]
getProduct(){
    let data={"search": "namkeen", "limit": 50, "offset": 0}
    this.store.namkeen(data).subscribe((res)=>{
        this.productlist=res
        console.log("product",this.productlist)
    })
}

  productDetail1(productItem: any) {
    console.log("hello hi ", productItem)
    this.router.navigate(['./simple-product/' + productItem._id])
  }
  
  handleAddToCarta(productObj,productItem) {
    console.log("productObj ", productObj)
    this.cart = []
    let cart = localStorage.getItem("cart");
    if (cart) {
      this.cart = JSON.parse(cart);
      console.log("cart ", this.cart)
      let filtered = this.cart.filter(product => product.id == productObj._id)
      console.log("filtered ", filtered)
      if (filtered.length == 0) {
        var pdata = {
          id: productObj._id,
          qty: this.qty
        }
        console.log("item._id12 ", pdata)
        console.log(pdata)
        this.cart.push(pdata)
        localStorage.setItem('cart', JSON.stringify(this.cart))
        this.show = 1
      } else {
        console.log(this.show)
        let index = this.cart.indexOf(filtered[0]);
        console.log("index ", index)
        this.cart.splice(index, 1)
        localStorage.setItem('cart', JSON.stringify(this.cart))
        this.show = null

      }
    } else {
      console.log("item._id ", productObj._id)
      var pdata = {
        id: productObj._id,
        qty: this.qty
      }
     
      this.cart.push(pdata)
      localStorage.setItem('cart', JSON.stringify(this.cart))
      this.show = 1
    }
    this.userService.cartUpdated.next(true);
  
   
     
    
  }
}