<!-- Start Facility Area -->
<section class="facility-area pt-100 pb-70 bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let Content of facilityContent;" style="margin-top: 30px;">
                <div class="single-facility-box">
                    <div class="icon">
                        <i class='{{Content.icon}}'></i>
                    </div>
                    <h3>{{Content.title}}</h3>
                    <p>{{Content.paragraph}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Facility Area -->