import { signup } from './../../../services/data-type';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { login } from 'src/app/services/data-type';
import { UserService } from 'src/app/services/user.service';
@Component({
    selector: 'app-my-account-page',
    templateUrl: './my-account-page.component.html',
    styleUrls: ['./my-account-page.component.scss']
})
export class MyAccountPageComponent implements OnInit {
  
     loginForm :FormGroup;
   registerForm :FormGroup
  
    showLogin = false;
   
    constructor(private loginservicepage: UserService,private router:Router) { }
    ngOnInit(): void {

    }

    signupform(user: signup){

        console.warn(user)      
      this.loginservicepage.SignupUser(user);  
    
      
  
     

     
    }

    Login(user: login){
     console.warn(user)
     localStorage.setItem('userEmail',user.email)
     localStorage.setItem('userPassword',user.password)
      this.loginservicepage.loginUser(user)
    } 

    openLogin(){

      this.showLogin = false;
  
    }
  
    openSignUp(){
      this.showLogin = true;
    }

    }

    
function signup() {
    throw new Error('Function not implemented.');
}

