import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    // http://localhost:4200/assets/img/Website%20Banner1.jpg
    pageTitle = [
        {
            bgImage: 'assets/img/namkeens.png',
            title: 'About Us'
        }
    ]

    aboutContent = [
        {
            subTitle: `ABOUT US`,
            title: `BhujiaVala Namkeen endeavours to be at the helm of the Food Revolution the nation with a vision to cement India’s place on the World Food Map. Focusing on promoting Indian heritage, culture and cuisine worldwide, the company has now customised Indian food for the overseas market as well. Introducing the world to our famed traditional hospitality, Pooja Namkeen is a name that has its roots in the centuries’ old native cuisine.

            Along with namkeen, the brand also has diversified into bakery products such as suji toast and different types of cookies which are wildly popular with the masses. The products on offer personify the culture of the city of Delhi with its rich heritage and vibrancy.
            
            The taste of New Delhi is what you get when you try some of the delicious namkeens and sweets from the house of Bhujiawala Namkeen.From an assortment laid out during festivals, to munching with friends or a midnight snack craving, Pooja Namkeen will always be a part of your household and its everlasting happiness. The company takes great pride in filling the shelves of its millions of customers with its products and enthralling the taste buds of children, adults and the elderly alike
            
            Backed by the experiences and expertise of over three decades, BhujiaVala Namkeen stands today as one of the brand leaders in this highly sensitive market of ready to eat snacks Relying solely upon uncompromising quality, Bhujiawala Namkeen has come a long way as an technologically advanced organization which began its journey as a cottage industry.`,
            paragraph: ``,
            content: [
                {
                    //title: `Our Goal`,
                    //paragraph: `It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
                },
                {
                    title: `Our Mission`,
                    paragraph: `Our mission is to deliver high quality and low-cost one stop platform for ecommerce, promotions, inventory and home delivery needs to our consumers, retailers, businesses and wholesalers.`
                },
                {
                    title: `Our Vision`,
                    paragraph: `Our vision is to bring all businesses and every individual of India on an ecommerce and mobile one stop commerce platform allowing them to focus on their primary needs, business activities, while reducing spam, time investments, cost in the safe & secure and trust-worthy SPOT environment and marketplace.`
                }
            ]
        }
    ]
    // aboutImage = [
    //     {
    //         img: 'assets/img/about-img1.jpg'
    //     },
    //     {
    //         img: 'assets/img/about-img2.jpg'
    //     }
    // ]

}