import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-shop-right-sidebar-page-two',
    templateUrl: './shop-right-sidebar-page-two.component.html',
    styleUrls: ['./shop-right-sidebar-page-two.component.scss']
})
export class ShopRightSidebarPageTwoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
      
    }
    pageTitle = [
        {
            bgImage: 'assets/img/namkeens.png',
            title: 'Ergonomic Desk Sofa'
        }
    ]
   
}